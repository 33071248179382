/*
 *
 */
import * as React from "react";
import styled from 'styled-components';
import { Link } from 'gatsby';
import stamp from './stamp.svg';

/*
 *
 */
const Page = styled.article`
	margin:0 auto;

	padding:5vmax 0;
	position:relative;
	z-index:1;

	@media (min-width:768px) {
		width:90%;
		max-width:920px;
	}

	&::after {
		position:absolute;
		top:0;
		right:0;
		z-index:-1;

		content:"";
		width:50%;
		height:100%;

		background:url(${stamp}) no-repeat top right;
		background-size:contain;
		opacity:.1;
	}

`;

const Title = styled.h1`
	margin:0 0 1em;
	font-weight:400;
	font-size:1.8rem;
	line-height:1.3em;
`;

const Intro = styled.div`
	margin:0 0 1em;

	font-weight:500;
	font-size:2.2rem;
	line-height:1.3em;

	@media (min-width:768px) {
		font-size:2.8rem;
	}

	@media (min-width:1024px) {
		font-size:3.8rem;
	}
`;

const Content = styled.div`
	font-size:1.66rem;
	line-height:1.4em;
	letter-spacing:0.015em;

	& h2 {
		margin:2em 0 .66em;

		&:first-child {
			margin-top:1em;
		}
	}

	& h3 {
		margin:2em 0 .5em;
	}

	& strong {
		font-weight:500;
	}

	@media (min-width:768px) {
		font-size:1.9rem;
	}

	@media (min-width:1024px) {
		font-size:2.1rem;
	}
`;

/*
 *
 */
export default ({ title=null, intro=null, content=null, children }) => {
	return (
		<Page>
			{title && (
				<Title>{title}</Title>
			)}
			{intro && (
				<Intro>{intro}</Intro>
			)}
			{content && (
				<Content>{content}</Content>
			)}
			{children}
		</Page>
	);
}
