/*
 *
 */
import * as React from "react";
import styled from 'styled-components';
import { Link } from 'gatsby';

/*
 *
 */
export const Button = styled.a`
	margin:${({ align }) => align === 'center' ? '0 auto' : '0'};
	display:table;
	padding:1.4em 2.338em 1.3em;

	font-weight:500;
	font-size:1.4rem;
	line-height:normal;
	letter-spacing:.04em;
	text-decoration:none;

	@media (min-width:1024px) {
		font-size:1.6rem;
	}

	&,
	&:active,
	&:visited {
		background:#AC4629;
		color:#fff;
	}

	&:hover {
		opacity:.8;
	}
`;

/*
 *
 */
export default ({ to, href, label, align='center' }) => {
	return (
		<Button as={to ? Link : href ? 'a' : 'div'} to={to} href={href} align={align}>
			{label}
		</Button>
	);
}
