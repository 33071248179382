/*
 *
 */
import * as React from 'react';
import Page from '../components/page';
import Button from '../components/button';

/*
 *
 */
const PageContent = () => {
	return (
		<Page
			title="La consultation individuelle pour adolescent"
			intro="L’adolescence, étape charnière entre l’enfance et l’âge adulte, peut être une période mouvementée tant pour l’adolescent que pour son entourage familial."
			content={(
				<React.Fragment>
					<p>
						L’adolescent doit faire face à des changements corporels et relationnels liés à la puberté ; Le désir d’autonomie va grandissant avec tous ses aléas ; la perte de l’insouciance de l’enfance le confronte à de nouvelles réalités.
					</p>

					<p>
						Tous ces éléments peuvent déstabiliser voire fragiliser l’adolescent.
						Ils peuvent conduire certains jeunes à des comportements problématiques, des
						conduites à risque, à la consommation de stupéfiant ; expression de leur
						souffrance. D’autres jeunes, au contraire, exprimeront leur mal-être par de
						l’angoisse, du repli sur soi, par un sentiment dépressif, par une perte de vitalité…
						Parler de leur problème est parfois difficile pour ces adolescents qui se sentent
						souvent incompris.
					</p>

					<p>
						La psychothérapie, en offrant un espace de parole bienveillant couvert par le
						secret professionnelle et la rencontre avec un adulte neutre, peut favoriser une
						prise de parole de façon plus spontanée.
					</p>

					<p>
						La consultation permettra à l’adolescent de (re)trouver ses propres ressources
						au sein de cette période de remaniements mais néanmoins extrêmement riche.
						Il pourra alors se questionner sur ses émotions, ses motivations et ses aspirations
						afin de mieux se comprendre et rebondir face à ses difficultés pour finalement
						trouver un apaisement à son mal-être.
					</p>

					<p>
						La demande de psychothérapie peut être initiée par un tiers ou par l’adolescent
						lui-même. Néanmoins, il est primordial de travailler avec l’accord de l’adolescent
						afin de pouvoir créer une réelle alliance thérapeutique, moteur indispensable au
						bon déroulement de la thérapie.
					</p>

					<br />
					<Button to="/rendez-vous/" label="Prendre un rendez-vous" />
				</React.Fragment>
			)}
		>
			<title>La consultation pour adolescent — Isabelle Kopp</title>
		</Page>
	);
};

export default PageContent;
